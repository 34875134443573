var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Update Router Info"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Route Name","label-for":"route-name"}},[_c('validation-provider',{attrs:{"name":"Route Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Route Name"},model:{value:(_vm.routerInfo.router_name),callback:function ($$v) {_vm.$set(_vm.routerInfo, "router_name", $$v)},expression:"routerInfo.router_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"IP Address","label-for":"id-address"}},[_c('validation-provider',{attrs:{"name":"IP Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter IP Address"},model:{value:(_vm.routerInfo.ip_address),callback:function ($$v) {_vm.$set(_vm.routerInfo, "ip_address", $$v)},expression:"routerInfo.ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"IP Port","label-for":"id-port"}},[_c('validation-provider',{attrs:{"name":"Port","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter IP Port"},model:{value:(_vm.routerInfo.api_port),callback:function ($$v) {_vm.$set(_vm.routerInfo, "api_port", $$v)},expression:"routerInfo.api_port"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"UserName","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"UserName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Enter Router User Name"},model:{value:(_vm.routerInfo.username),callback:function ($$v) {_vm.$set(_vm.routerInfo, "username", $$v)},expression:"routerInfo.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.routerInfo.password),callback:function ($$v) {_vm.$set(_vm.routerInfo, "password", $$v)},expression:"routerInfo.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.status},model:{value:(_vm.routerInfo.status),callback:function ($$v) {_vm.$set(_vm.routerInfo, "status", $$v)},expression:"routerInfo.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }